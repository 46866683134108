import React from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

const AboutPage = () => {
  const { wordpressAcfOptions, allWordpressAcfContributor } = useStaticQuery(
    PUBLICATION_DETAILS
  )
  const {
    publication_details,
    publication_name,
    pub_contributors,
  } = wordpressAcfOptions.options
  const { nodes } = allWordpressAcfContributor

  return (
    <Layout
      seo={{ title: "About" }}
      mainHeader={"About " + publication_name}
      mainContent={
        <Container>
          <Title>About {publication_name}</Title>
          <div dangerouslySetInnerHTML={{ __html: publication_details }} />
          {pub_contributors &&
            pub_contributors?.map(
              (row, i) =>
                row?.contributors_heading && (
                  <div key={i}>
                    <h3>{row?.contributors_heading}</h3>
                    {row?.contributors?.map((contributor, i) => {
                      const person = nodes?.filter(
                        node => node?.wordpress_id === contributor?.wordpress_id
                      )[0]
                      return (
                        contributor.post_title && (
                          <Contributor
                            key={i}
                            noPhoto={person.acf.photo === null}
                          >
                            {person.acf.photo !== null && (
                              <Img
                                fluid={
                                  person.acf.photo.localFile.childImageSharp
                                    .fluid
                                }
                                alt=""
                                style={{ height: "250px" }}
                              />
                            )}
                            <div className="content">
                              <h5>
                                {contributor.post_title} -{" "}
                                {person.acf.qualifications}
                              </h5>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: person.acf.bio,
                                }}
                              />
                            </div>
                          </Contributor>
                        )
                      )
                    })}
                  </div>
                )
            )}
        </Container>
      }
    />
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================

const Contributor = styled.div`
  display: grid;
  grid-template-columns: ${({ noPhoto }) =>
    noPhoto ? "1fr" : "minmax(200px, 1fr) 4fr"};
  grid-gap: 0px;
  padding: 10px;
  margin: 0 0 20px 0;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 20px;
  border-radius: 12px;
  h5 {
    margin: 0;
  }
  img {
    border-radius: 5px;
  }
  .content {
    padding: 0 20px 20px 20px;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    .content {
      padding: 20px;
    }
  }
`

const Container = styled.div`
  padding: 40px;
  h3,
  h4,
  h5,
  h6 {
    font-family: "Poppins", sans-serif;
    color: rgba(0, 0, 0, 0.85);
  }
  h3 {
    font-size: 26px;
    border-bottom: 1px solid #ccc;
  }
  h4 {
    font-size: 23px;
  }
  h5 {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    padding: 20px;
  }
`

const Title = styled.h2`
  font-family: "Poppins", sans-serif;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  line-height: 1.2;
`

const PUBLICATION_DETAILS = graphql`
  query {
    wordpressAcfOptions {
      options {
        publication_details
        publication_name
        pub_contributors {
          contributors_heading
          contributors {
            post_title
            wordpress_id
          }
        }
      }
    }
    allWordpressAcfContributor {
      nodes {
        id
        wordpress_id
        acf {
          photo {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          bio
          qualifications
        }
      }
    }
  }
`

export default AboutPage
